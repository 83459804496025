import { axiosConfig } from '../api/axios-config.js'

// 聯絡我們
export const contact = {
  // 取得清單
  get: (id, token) => axiosConfig.get(`/api/contact/${id || ''}`, token),

  // 更新
  update: (id, data, token) => axiosConfig.patch(`/api/contact/${id}`, data, token)
}
